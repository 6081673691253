<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑账号' : '创建账号'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model.trim="form.data.name"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="account">
          <el-input
            v-model.trim="form.data.account"
            maxlength="11"
            placeholder="请输入电话"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="!isChange">
          <el-input
            v-model.trim="form.data.password"
            placeholder="请输入密码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role_type">
          <el-radio-group v-model="form.data.role_type" v-removeAriaHidden>
            <el-radio :label="1">超级管理员</el-radio>
            <el-radio :label="2">管理员</el-radio>
            <el-radio :label="3">员工</el-radio>
            <el-radio :label="4">财务</el-radio>
          </el-radio-group>
          <div>
            <i class="el-icon-info" style="color:#e6a23c;"></i>
            <span style="color:#e6a23c;">{{strHint[form.data.role_type - 1]}}</span>
          </div>
        </el-form-item>
        <!-- 超级管理员账号和财务账号不需要选地区 -->
        <el-form-item label="地区" prop="area" v-if="form.data.role_type == 2 || form.data.role_type == 3">
          <el-cascader
            v-model="form.data.area"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            clearable
            style="width:100%;">
          </el-cascader>
        </el-form-item>
        <!-- <el-form-item label="权限" prop="access">
          <el-checkbox-group v-model="form.data.access" :max="2" @change="changeAccess($event)">
            <el-checkbox label="1">后台端</el-checkbox>
            <el-checkbox label="2" :disabled="isShow3">库管端</el-checkbox>
            <el-checkbox label="3" :disabled="isShow2">配送端</el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  export default {
    name: 'companyContractEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            account: '',
            password: '',
            area: '',
            role_type: 1, //色类型：1-超级管理员；2-管理员；3-员工；4-财务
            access: [],
          },
          rules: {
            name: [{ required: true, message:'请输入姓名', trigger: 'change'}],
            account: [
              { required: true, message:'请输入电话', trigger: 'change'},
              {
                pattern: REG_EXP.mobilePhone,
                message: '请输入正确的手机号',
                trigger: 'change'
              }
            ],
            password: [{ required: true, message:'请输入密码', trigger: 'change'}],
            area: [{ required: true, message:'请选择地区', trigger: 'change'}],
            access: [{ required: true, message:'请选择权限', trigger: 'change'}],
          }
        },
        isShow2: false,
        isShow3: false,
        areaArr: [],
        strHint: [
          '具有管理后台所有查看及管理权限',
          '具有该地区管理后台所有查看及管理权限',
          '具有该地区员工所有查看及管理权限',
          '具有管理后台财务相关查看及管理权限',
        ],
        
      }
    },
    methods: {
      getDetail(row) {
        this.getArea();
        if(!!row.id) {
          this.isChange = true
          common.deepClone(this.form.data, row)
          this.form.data.area = [row.province, row.city];
          this.form.data.access = row.access.split('|')
          console.log(this.form.data.access)
          this.isShow2 = this.form.data.access.includes('2')
          this.isShow3 = this.form.data.access.includes('3')
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 获取全国省市区
      getArea() {
        this.$http.post('/common/district/all', {is_all:1}).then(res => {
          if(res.code === 1) {
            this.areaArr = res.data.list;
            for (const key in this.areaArr) {
              // 删除第三层children属性
              for (const i in this.areaArr[key].children) {
                delete this.areaArr[key].children[i].children
              }
            }
          }
        })
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              account: this.form.data.account,
              password: this.form.data.password,
              province: this.form.data.role_type == 1 ? '' : this.form.data.area[0],// 超级管理员不要地区
              city: this.form.data.role_type == 1 ? '' : this.form.data.area[1], // 超级管理员不要地区
              role_type: this.form.data.role_type,
              // access: this.form.data.access.sort().join('|'),
            };
            console.log(_params)
            let apiUrl = this.isChange ? '/admin/admin/edit' : '/admin/admin/create'
            // return
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      changeAccess(value) {
        this.isShow2 = value.includes('2')
        this.isShow3 = value.includes('3')
      },

    }
  }
</script>
